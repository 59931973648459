import React, { useState } from "react";
import axios from "axios";
import Layout from "../components/Layout";
import MuiAlert from "@material-ui/lab/Alert";
import { getPrice } from "../constants/prix";
import { campaignObjectifs } from "../constants/campaignConstant";
import {
  Button,
  CircularProgress,
  Typography,
  TextField,
  MenuItem,
} from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import { set } from "lodash";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function BonDeCommande() {
  const campaignObjectifData = [...campaignObjectifs];
  const [indexObjectif, setIndexObjectif] = useState(0);
  const [match, setMatch] = useState(null);

  const initialValue = {
    name: "",
    campaignName: "",
    orderNumber: "",
    clientZoho: "",
    campagnes: [{ network: "", budget: "", goal: "" }],
    bdcFile: null,
  };

  const [loading, setLoading] = useState(false);
  const [globalState, setGlobalState] = useState(initialValue);
  const [index, setIndex] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);

  // const listeDesMarques = lesMarques.map((element) =>{ return element.data.marques.toUpperCase()})

  const globalFormChange = (e) => {
    if (e.target.id === "bdcFile") {
      let copy = { ...globalState };
      copy.bdcFile = e.target.files[0];
      setGlobalState(copy);
    } else if (e.target.id === "name") {
      setMatch(false);
      setIndex(null);
      setGlobalState({ ...globalState, [e.target.id]: e.target.value });
      // for (let i = 0; i < lesMarques.length; i++) {
      //   if (
      //     lesMarques[i].data.Possibilit__d_appelation.includes(
      //       e.target.value.toUpperCase()
      //     )
      //   ) {
      //     setMatch(true);
      //     setIndex(i);

      //     break;
      //   }
      // }
    } else {
      setGlobalState({ ...globalState, [e.target.id]: e.target.value });
    }
  };

  // console.log(globalState.bdcFile);
  const addCampaign = (e) => {
    let copyOfGlobalState = { ...globalState };
    copyOfGlobalState.campagnes.push({ network: "", budget: "", goal: "" });
    setGlobalState(copyOfGlobalState);
  };

  const changeCampaignItem = (e, index) => {
    let copyOfarray = [...globalState["campagnes"]];
    copyOfarray[index][`${e.target.name}`] = e.target.value;
    setGlobalState({ ...globalState, campagnes: copyOfarray });
  };

  function RemoveAccents(str) {
    var accents =
      "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž °";
    var accentsOut =
      "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz__";
    str = str.split("");
    var strLen = str.length;
    var i, x;
    for (i = 0; i < strLen; i++) {
      if ((x = accents.indexOf(str[i])) != -1) {
        str[i] = accentsOut[x];
      }
    }
    return str.join("");
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setConfirmationMessage(null);

    (async () => {
      let clientInfo = await axios({
        method: "get",
        url: `/api/airtableFetchClient?client=${globalState.name}`,
      })
        .then((res) => {
          // console.log("res", res);
          return res;
        })
        .catch((error) => {
          console.log("reponse : ", error);
        });

      // console.log("clientInfo", clientInfo);

      if (clientInfo.data && clientInfo.data.length > 0) {
        // console.log(clientInfo.data[0]["montant_due"]);
        // console.log(clientInfo.data[0]["Pour_le_compte_de"]);

        if (
          clientInfo.data[0].limite_de_credit -
            clientInfo.data[0].montant_due >=
          0
        ) {
          var config = {
            method: "post",
            url: "https://content.dropboxapi.com/2/files/upload",
            headers: {
              Authorization:
                "Bearer wLm2AxTUsyAAAAAAAAAAAeRq6kWOcC7jQmfhZDXBOL2VoWoBzqy6IrRYa88W1_n7",
              "Dropbox-API-Arg": `{"path": "/BDC/${
                globalState.name
              }_${RemoveAccents(globalState.campaignName)}_${RemoveAccents(
                globalState.orderNumber
              )}_${Date.now()}.pdf"}`,
              "Content-Type": "application/octet-stream",
            },
            data: globalState.bdcFile,
          };

          let upload = await axios(config)
            .then((res) => {
              console.log(res.data);
              return res.data.path_lower;
            })
            .catch(function (error) {
              console.log(error);
            });

          console.log(upload);

          let sharableLink = await axios({
            method: "post",
            url:
              "https://api.dropboxapi.com/2/sharing/create_shared_link_with_settings",
            headers: {
              Authorization:
                "Bearer wLm2AxTUsyAAAAAAAAAAAeRq6kWOcC7jQmfhZDXBOL2VoWoBzqy6IrRYa88W1_n7",
              "Content-Type": "application/json ;charset=ISO-8859-1",
            },
            data: JSON.stringify({
              path: upload,
              settings: {
                requested_visibility: "public",
                audience: "public",
                access: "viewer",
              },
            }),
          })
            .then(function (response) {
              console.log(response);

              return response.data.url;
            })
            .catch(function (error) {
              console.log(error);
            });

          console.log("sharableLink", sharableLink);
          // setGlobalState({ ...globalState, bdcFile: sharableLink });

          if (
            sharableLink &&
            (sharableLink !== "" || sharableLink !== undefined)
          ) {
            let zohoItems = globalState.campagnes.map((el) => {
              let prix = getPrice(
                Number(el.budget),
                el.network,
                el.goal,
                // clientInfo.data[0].Nom_sur_ZOHO_BOOK,
                globalState.name
              );
              return {
                quantity: prix.kpiZoho,
                rate: prix.prix_unitaire,
                bcy_rate: prix.prix_unitaire,
                unit: prix.unite,
                discount_amount: 0,
                discount: 0,
                name: prix.articleZoho,
              };
            });
            let invoiceId = await axios({
              method: "post",
              url: "https://enuyl1gs2sis7hj.m.pipedream.net",
              data: {
                client: clientInfo.data[0].Nom_sur_ZOHO_BOOK,
                zohoItems: zohoItems,
              },
              headers: {
                "Content-Type": "application/json",
              },
            })
              .then((res) => {
                console.log(res.data.message);
                return res.data.message;
              })
              .catch((error) => {
                console.log(error);
                return error;
              });
            let createCampaign = await axios({
              method: "post",
              url: `/api/airtableCreateCampaign`,
              data: {
                orderNumber: globalState.orderNumber,
                campaignName: globalState.campaignName,
                file: sharableLink,
                idEntreprise: clientInfo.data[0].Pour_le_compte_de,
                campagnes: globalState.campagnes,
                clientZoho: clientInfo.data[0].Nom_sur_ZOHO_BOOK,
                clientName: clientInfo.data[0].marques,
                invoiceId: invoiceId,
                marque: globalState.name,
                zohoItems: zohoItems,
              },
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
              .then((res) => {
                axios({
                  method: "post",
                  url: `/api/gmailapiBdc`,
                  data: {
                    client: clientInfo.data[0].marques,
                    campagnes: globalState.campagnes,
                    lienCampagne: res.data.fields["Lien de la campagne"],
                    sujet_du_mail: res.data.fields["Sujet du mail"],
                    email_to: res.data.fields["EMAIL TO"],
                    email_cc: res.data.fields["Email CC"]
                      ? res.data.fields["Email CC"]
                      : "",
                  },
                  header: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                })
                  .then((res) => {
                    console.log("email envoyé");
                    setConfirmationMessage(
                      <Alert severity="success">
                        Votre bon de commande a bien été reçu
                      </Alert>
                    );
                    setGlobalState(initialValue);
                    console.log(res.statusText);
                    setLoading(false);
                    setTimeout(function () {
                      setConfirmationMessage(null);
                    }, 3000);
                  })
                  .catch((error) => {
                    console.log("email non envoyé", error);
                    setLoading(false);
                    setConfirmationMessage(
                      <Alert severity="error">{error}</Alert>
                    );
                    setTimeout(function () {
                      setConfirmationMessage(null);
                    }, 5000);
                  });
                return res;
              })
              .catch((e) => {
                console.log(e);
                setLoading(false);
                setConfirmationMessage(<Alert severity="error">{e}</Alert>);
                setTimeout(function () {
                  setConfirmationMessage(null);
                }, 5000);
              });
            console.log(createCampaign);
          } else {
            setConfirmationMessage(
              <Alert severity="error">
                Le bon de commande n’a pas pu être téléchargés ,Veuillez
                réessayer a nouveaux
              </Alert>
            );
            setLoading(false);
          }
        } else {
          setConfirmationMessage(
            <Alert severity="error">
              Montant due superieur a la limite autorisé pour ce compte,
              Veuillez procéder au paiement du montant due afin de de pouvoir
              ajouté de nouveaux bon de commande
            </Alert>
          );
          setLoading(false);
        }
      } else {
        setConfirmationMessage(
          <Alert severity="error">
            Ce client n'existe pas veuillez contacté votre administrateur
          </Alert>
        );
        let copy = { ...globalState };
        copy.name = "";
        setGlobalState(copy);
        setLoading(false);
        setTimeout(function () {
          setConfirmationMessage(null);
        }, 6000);
      }
    })();
  };

  return (
    <Layout>
      <form id="main-form" onSubmit={handleSubmit}>
        <section className="contact-page">
          <article className="contact-form">
            {confirmationMessage}
            <Typography component="h3" variant="h3">
              Bon de commande
            </Typography>
            <div className="main-component">
              <div className="form-group">
                <TextField
                  value={globalState.name}
                  onChange={globalFormChange}
                  id="name"
                  name="name"
                  variant="outlined"
                  label="Nom Client"
                  margin="normal"
                  fullWidth
                  required
                />

                <TextField
                  value={globalState.campaignName}
                  onChange={globalFormChange}
                  id="campaignName"
                  name="campaignName"
                  variant="outlined"
                  label="Nom de la campagne"
                  margin="normal"
                  fullWidth
                  required
                />

                <TextField
                  value={globalState.orderNumber}
                  onChange={globalFormChange}
                  id="orderNumber"
                  variant="outlined"
                  label="Numero de bon de commande"
                  margin="normal"
                  fullWidth
                  required
                />

                <div>
                  <div className="">
                    {globalState.campagnes.map((element, index) => {
                      return (
                        <div key={index} className="networkBlock">
                          <TextField
                            value={element.network}
                            name="network"
                            select
                            variant="outlined"
                            label="Type de campagne"
                            id={`network_${index}`}
                            form="main-form"
                            onChange={(e) => {
                              changeCampaignItem(e, index);
                              let copy = { ...globalState };
                              copy.campagnes[index].goal = "";

                              setGlobalState(copy);

                              for (
                                let i = 0;
                                i < campaignObjectifData.length;
                                i++
                              ) {
                                if (
                                  campaignObjectifData[i].network ===
                                  element.network
                                )
                                  setIndexObjectif(campaignObjectifData[i].id);
                              }
                            }}
                            required
                          >
                            <MenuItem value="" disabled></MenuItem>
                            <MenuItem value="Facebook">Facebook</MenuItem>
                            <MenuItem value="Linkedin">Linkedin</MenuItem>
                            <MenuItem value="Instagram">Instagram</MenuItem>
                            <MenuItem value="Snapchat">Snapchat</MenuItem>
                            <MenuItem value="Display">Display</MenuItem>
                            <MenuItem value="Youtube">Youtube</MenuItem>
                            <MenuItem value="Search">Search</MenuItem>
                          </TextField>

                          <TextField
                            name="goal"
                            select
                            label="Objectif"
                            variant="outlined"
                            disabled={element.network === ""}
                            required
                            value={element.goal}
                            onChange={(e) => {
                              changeCampaignItem(e, index);
                            }}
                          >
                            {campaignObjectifData &&
                              campaignObjectifData[indexObjectif].objective.map(
                                (item, key) => {
                                  return (
                                    <MenuItem key={key} value={item}>
                                      {item}
                                    </MenuItem>
                                  );
                                }
                              )}
                          </TextField>

                          <TextField
                            value={element.budget}
                            name="budget"
                            type="number"
                            required
                            variant="outlined"
                            id={`budget_${index}`}
                            label="budget"
                            form="main-form"
                            onChange={(e) => changeCampaignItem(e, index)}
                          />
                          <IconButton
                            color="primary"
                            component="button"
                            disabled={globalState.campagnes.length <= 1}
                            onClick={() => {
                              let copyOfarray = [...globalState["campagnes"]];

                              copyOfarray.splice(index, 1);
                              setGlobalState({
                                ...globalState,
                                campagnes: copyOfarray,
                              });
                            }}
                            aria-label="delete"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <IconButton
                  color="primary"
                  component="button"
                  style={{
                    color: "green",

                    display: "grid",
                    marginLeft: "auto",
                    marginRight: "10px",
                    border: 0,
                    padding: 12,
                    boxSizing: "border-box",
                  }}
                  type="button"
                  onClick={addCampaign}
                  id="addCampagnes"
                >
                  <AddCircleIcon />
                </IconButton>
                <input
                  type="file"
                  name="bdcFile"
                  id="bdcFile"
                  form="main-form"
                  required
                  style={{
                    width: "100%",
                    padding: "5rem",

                    marginTop: "2rem",
                    marginBottom: "2rem",
                    textAlign: "center",
                  }}
                  onChange={globalFormChange}
                />
              </div>
              <Button
                disabled={loading}
                type="submit"
                form="main-form"
                color="primary"
                variant="contained"
                size="large"
                className="submit-btn"
              >
                {loading ? <CircularProgress /> : "Envoyer"}
              </Button>
            </div>
          </article>
        </section>
      </form>
    </Layout>
  );
}

export default BonDeCommande;
