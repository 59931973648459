function getPrice(budget, reseaux, objectif, client) {
  let network = reseaux.toLowerCase();
  objectif = objectif.toLowerCase();

  let tauxdechange = {
    dollar: {
      valeur: 215,
      symbole: "$",
    },
    dirhem: {
      valeur: 60,
      symbole: "AED",
    },
    euro: {
      valeur: 228.261,
      symbole: "Euro",
    },
  };

  let objet = {
    facebook: {
      tauxdechange: {
        valeur: tauxdechange.euro.valeur,
        symbole: tauxdechange.euro.symbole,
      },
      ratio: 2.3,
      objectif: {
        impression: {
          prix: 50,
          unite: "CPM",
          encherMoyen: 0.15,
          articleZoho: "Promotion de contenu : Objectif - Impression",
          ratio: 2.3,
        },
        interaction: {
          prix: 4,
          unite: "CPE",
          encherMoyen: 0.005,
          articleZoho: "Promotion de contenu : Objectif - Interaction",
        },
        "recrutement de fans": {
          prix: 13.2,
          unite: "FANS",
          encherMoyen: 0.02,
          articleZoho: "Promotion de contenu : Objectif - Recrutement De Fans",
        },
        traffic: {
          prix: 13.2,
          unite: "CPC",
          encherMoyen: 0.02,
          articleZoho: "Promotion de contenu : Objectif - Traffic",
        },
        "vue video": {
          prix: 1.2,
          unite: "CPV",
          encherMoyen: 0.003,
          articleZoho: "Promotion de contenu : Objectif - Vue Video",
        },
        story: {
          prix: 90,
          unite: "CPM",
          encherMoyen: 0.35,
          ratio: 3,
          articleZoho: "Promotion de contenu : Objectif - Story",
        },
      },
    },

    instagram: {
      tauxdechange: {
        valeur: tauxdechange.euro.valeur,
        symbole: tauxdechange.euro.symbole,
      },
      ratio: 2.3,
      objectif: {
        impression: {
          prix: 50,
          unite: "CPM",
          encherMoyen: 0.15,
          ratio: 2.3,
          articleZoho: "Promotion de contenu - IG : Objectif - Impression",
        },
        interaction: {
          prix: 4,
          unite: "CPE",
          encherMoyen: 0.005,
          ratio: 2.3,
          articleZoho: "Promotion de contenu - IG : Objectif - Interaction",
        },
        story: {
          prix: 90,
          unite: "CPM",
          encherMoyen: 0.35,
          ratio: 2.3,
          articleZoho: "Promotion de contenu - IG : Objectif - Story",
        },
        traffic: {
          prix: 13.2,
          unite: "CPC",
          encherMoyen: 0.02,
          ratio: 2.3,
          articleZoho: "Promotion de contenu - IG : Objectif - Traffic",
        },
        "vue video": {
          prix: 2.35,
          unite: "CPV",
          encherMoyen: 0.003,
          ratio: 2.3,
          articleZoho: "Promotion de contenu - IG : Objectif - Vue Video",
        },
      },
    },

    linkedin: {
      tauxdechange: {
        valeur: tauxdechange.dollar.valeur,
        symbole: tauxdechange.dollar.symbole,
      },
      ratio: 3,
      objectif: {
        impression: {
          prix: 3500,
          unite: "CPM",
          encherMoyen: 7.15,
          articleZoho: "Promotion de contenu BtoB : Objectif - Impression",
        },

        traffic: {
          prix: 400,
          unite: "CPC",
          encherMoyen: 1.02,
          articleZoho: "Promotion de contenu BtoB : Objectif - Traffic",
        },
        inmail: {
          prix: 280,
          unite: "Emails",
          encherMoyen: 0.7,
          articleZoho: "Promotion de contenu BtoB : Objectif - In Mail",
        },
      },
    },

    youtube: {
      ratio: 5,
      tauxdechange: {
        valeur: tauxdechange.dirhem.valeur,
        symbole: tauxdechange.dirhem.symbole,
      },
      objectif: {
        impression: {
          prix: 300,
          unite: "CPM",
          encherMoyen: 1.5,
          articleZoho: "Insertion Video : Objectif - Impression",
        },

        "vue video": {
          prix: 1.8,
          unite: "CPV",
          encherMoyen: 0.005,
          articleZoho: "Insertion Video : Objectif - Vue Video",
        },
      },
    },

    display: {
      tauxdechange: {
        valeur: tauxdechange.dirhem.valeur,
        symbole: tauxdechange.dirhem.symbole,
      },
      ratio: 5,
      objectif: {
        impression: {
          prix: 20,
          unite: "CPM",
          encherMoyen: 0.06,
          articleZoho: "Insertion Graphique : Objectif - Impression",
        },

        traffic: {
          prix: 5.6,
          unite: "CPC",
          encherMoyen: 0.01,
          articleZoho: "Insertion Graphique : Objectif - Vue Video",
        },
      },
    },

    snapchat: {
      tauxdechange: {
        valeur: tauxdechange.dirhem.valeur,
        symbole: tauxdechange.dirhem.symbole,
      },
      ratio: 3,
      objectif: {
        swipup: {
          prix: 383,
          unite: "CPM",
          encherMoyen: 0.06,
          articleZoho: "Promotion de contenu - GenZ : Objectif - Swipup",
        },

        impression: {
          prix: 1414,
          unite: "CPM",
          encherMoyen: 1,
          articleZoho: "Promotion de contenu - GenZ : Objectif - Impression",
        },

        geofiltre: {
          prix: 273,
          unite: "CPC",
          encherMoyen: 1,
          articleZoho: "Promotion de contenu - GenZ : Objectif - Geofiltre",
        },
      },
    },

    search: {
      tauxdechange: {
        valeur: tauxdechange.dirhem.valeur,
        symbole: tauxdechange.dirhem.symbole,
      },
      ratio: 2.3,
      objectif: {
        traffic: {
          prix: 60,
          unite: "CPC",
          encherMoyen: 0.3,
          articleZoho:
            "Promotion sur les moteurs de recherche : Objectif - Traffic",
        },
      },
    },
  };

  if (client === "BRANDT") {
    console.log("c'est brandt");
    objet.facebook.objectif.impression.prix = 52;
    objet.instagram.objectif.impression.prix = 50;
    objet.facebook.objectif["vue video"].prix = 2.3;
    objet.instagram.objectif["vue video"].prix = 2.3;
    objet.instagram.objectif.story.prix = 90;
    objet.youtube.objectif["vue video"].prix = 2.3;
    objet.youtube.objectif.impression.prix = 870;
    objet.linkedin.objectif.impression.prix = 3680;
    objet.display.objectif.impression.prix = 40;
    objet.display.objectif.traffic.prix = 6;
    objet.facebook.ratio = 2.3;
    objet.instagram.ratio = 2.3;
    objet.linkedin.ratio = 3;
    objet.display.ratio = 5;
    objet.youtube.ratio = 5;
    objet.snapchat.ratio = 3;
    objet.search.ratio = 2.3;
  }
  // if (client === "MAGPHARM Laboratoires") {
  //   objet.facebook.objectif.impression.prix = 41.4;
  //   objet.instagram.objectif.impression.prix = 45.54;
  //   objet.facebook.objectif["vue video"].prix = 1.26;
  //   objet.instagram.objectif.story.prix = 150;
  //   objet.instagram.objectif["vue video"].prix = 1.26;
  //   objet.youtube.objectif["vue video"].prix = 2.3;
  //   objet.youtube.objectif.impression.prix = 870;
  //   objet.linkedin.objectif.impression.prix = 3680;
  //   objet.display.objectif.impression.prix = 20;
  //   objet.display.objectif.traffic.prix = 5.6;

  //   objet.facebook.ratio = 2.3;
  //   objet.instagram.ratio = 2.3;
  //   objet.linkedin.ratio = 2.3;
  //   objet.display.ratio = 5;
  //   objet.youtube.ratio = 5;
  //   objet.snapchat.ratio = 2.3;
  //   objet.search.ratio = 2.3;
  // }
  else if (client === "TWISCO") {
    console.log("c'est twisco");
    objet.facebook.objectif.impression.prix = 85.82;
    objet.instagram.objectif.impression.prix = 100.86;
    objet.facebook.objectif.interaction.prix = 4;
    objet.instagram.objectif.interaction.prix = 4;
    objet.youtube.objectif["vue video"].prix = 2.2;
    objet.youtube.objectif.impression.prix = 350;
    objet.facebook.objectif["vue video"].prix = 1.8;
    objet.display.objectif.impression.prix = 20;

    objet.facebook.ratio = 2.76;
    objet.instagram.ratio = 2.76;
  } else if (client === "LOYA") {
    console.log("c'est loya");
    objet.facebook.objectif.impression.prix = 80;
    objet.instagram.objectif.impression.prix = 90;
    objet.facebook.objectif.interaction.prix = 4;
    objet.instagram.objectif.interaction.prix = 4;
    objet.youtube.objectif["vue video"].prix = 2.2;
    objet.youtube.objectif.impression.prix = 350;
    objet.facebook.objectif["vue video"].prix = 1.8;
    objet.display.objectif.impression.prix = 20;

    objet.facebook.ratio = 2.76;
    objet.instagram.ratio = 2.76;
  } else if (client === "MARGARINE SOL") {
    console.log("c'est margarine sol");
    objet.facebook.objectif.impression.prix = 80;
    objet.instagram.objectif.impression.prix = 90;
    objet.facebook.objectif.interaction.prix = 4;
    objet.instagram.objectif.interaction.prix = 4;
    objet.youtube.objectif["vue video"].prix = 2.2;
    objet.youtube.objectif.impression.prix = 350;
    objet.facebook.objectif["vue video"].prix = 1.8;
    objet.display.objectif.impression.prix = 20;

    objet.facebook.ratio = 2.76;
    objet.instagram.ratio = 2.76;
  } else if (client === "NESTLE") {
    console.log("c'est NESTLE");
    objet.facebook.objectif.impression.prix = 80;
    objet.instagram.objectif.impression.prix = 90;
    objet.facebook.objectif.interaction.prix = 4;
    objet.instagram.objectif.interaction.prix = 4;
    objet.youtube.objectif["vue video"].prix = 2.2;
    objet.youtube.objectif.impression.prix = 350;
    objet.facebook.objectif["vue video"].prix = 1.8;
    objet.display.objectif.impression.prix = 20;

    objet.facebook.ratio = 2.76;
    objet.instagram.ratio = 2.76;
  } else if (client === "HAMOUD") {
    console.log("c'est HAMOUD");
    objet.facebook.objectif.impression.prix = 40;
    objet.instagram.objectif.impression.prix = 40;
    objet.facebook.objectif.interaction.prix = 4;
    objet.instagram.objectif.interaction.prix = 4;
    objet.youtube.objectif["vue video"].prix = 1.8;
    objet.youtube.objectif.impression.prix = 150;
    objet.facebook.objectif["vue video"].prix = 1.2;
    objet.instagram.objectif["vue video"].prix = 2.3;
    objet.display.objectif.impression.prix = 40;

    objet.facebook.ratio = 2.76;
    objet.instagram.ratio = 2.76;
  } else if (client === "AMILA") {
    console.log("c'est amila");
    objet.facebook.objectif.impression.prix = 45;
    objet.instagram.objectif.impression.prix = 45;
    objet.facebook.objectif.interaction.prix = 4;
    objet.instagram.objectif.interaction.prix = 4;
    objet.youtube.objectif["vue video"].prix = 2.2;
    objet.youtube.objectif.impression.prix = 350;
    objet.facebook.objectif["vue video"].prix = 1.8;
    objet.display.objectif.impression.prix = 20;

    objet.facebook.ratio = 2.76;
    objet.instagram.ratio = 2.76;
  } else if (
    client === "LA VACHE QUI RIT" ||
    client === "LVQR CHEF" ||
    client === "KIRI"
  ) {
    console.log("c'est vache qui rit");
    objet.facebook.objectif.impression.prix = 55;
    objet.instagram.objectif.impression.prix = 55;
    objet.facebook.objectif.interaction.prix = 4;
    objet.instagram.objectif.interaction.prix = 4;
    objet.youtube.objectif["vue video"].prix = 2.2;
    objet.youtube.objectif.impression.prix = 350;
    objet.facebook.objectif["vue video"].prix = 1.8;
    objet.display.objectif.impression.prix = 20;

    objet.facebook.ratio = 2.76;
    objet.instagram.ratio = 2.76;
  } else if (client === "IRIS") {
    console.log("c'est saterex");
    objet.facebook.objectif.impression.prix = 41.4;
    objet.instagram.objectif.impression.prix = 41.4;
    objet.instagram.objectif.story.prix = 150;
    objet.youtube.objectif["vue video"].prix = 1.8;
    objet.youtube.objectif.impression.prix = 870;
    objet.linkedin.objectif.impression.prix = 3680;
    objet.display.objectif.impression.prix = 36.8;
    objet.facebook.objectif.interaction.prix = 4;
    objet.instagram.objectif.interaction.prix = 4;

    objet.facebook.ratio = 2.1159;
    objet.instagram.ratio = 2.1159;
    objet.linkedin.ratio = 2.3;
    objet.display.ratio = 5;
    objet.youtube.ratio = 5;
    objet.snapchat.ratio = 2.3;
    objet.search.ratio = 2.3;
  }
  // else if (
  //   client === "KIRI" ||
  //   client === "LVQR CHEF" ||
  //   client === "SIMPLY" ||
  //   client === "MAXON" ||
  //   client === "PALMARY CAMEROUN" ||
  //   client === "MOMENT" ||
  //   client === "KOOL" ||
  //   client === "HAPPY"
  // ) {
  //   objet.facebook.objectif.impression.prix = 55;
  //   objet.instagram.objectif.impression.prix = 55;
  //   objet.facebook.objectif.story.prix = 90;
  //   objet.instagram.objectif.story.prix = 90;
  //   objet.facebook.objectif.interaction.prix = 4;
  //   objet.instagram.objectif.interaction.prix = 4;
  //   objet.youtube.objectif["vue video"].prix = 1.8;
  //   objet.facebook.objectif["vue video"].prix = 1.2;
  //   objet.instagram.objectif["vue video"].prix = 1.2;

  //   objet.youtube.objectif.impression.prix = 870;
  //   objet.linkedin.objectif.impression.prix = 3680;
  //   objet.display.objectif.impression.prix = 30;
  //   objet.display.objectif.traffic.prix = 4;

  //   objet.facebook.ratio = 2.3;
  //   objet.instagram.ratio = 2.3;
  //   objet.linkedin.ratio = 2.3;
  //   objet.display.ratio = 5;
  //   objet.youtube.ratio = 5;
  //   objet.snapchat.ratio = 2.3;
  //   objet.search.ratio = 2.3;
  // }
  else if (client === "CAPS" || client === "SWIFT" || client === "PIACELLI") {
    objet.facebook.objectif.impression.prix = 55;
    objet.instagram.objectif.impression.prix = 65;
    objet.facebook.objectif.story.prix = 90;
    objet.instagram.objectif.story.prix = 90;
    objet.facebook.objectif.interaction.prix = 4;
    objet.instagram.objectif.interaction.prix = 4;
    objet.youtube.objectif["vue video"].prix = 1.7;
    objet.facebook.objectif["vue video"].prix = 2.35;
    objet.instagram.objectif["vue video"].prix = 2.35;

    objet.youtube.objectif.impression.prix = 870;
    objet.linkedin.objectif.impression.prix = 3680;
    objet.display.objectif.impression.prix = 20;

    objet.facebook.ratio = 2.3;
    objet.instagram.ratio = 2.3;
    objet.linkedin.ratio = 2.3;
    objet.display.ratio = 4;
    objet.youtube.ratio = 5;
    objet.snapchat.ratio = 2.3;
    objet.search.ratio = 2.3;
  } else if (client === "LACTEL" || client === "PRESIDENT") {
    console.log("c'est lactel");
    objet.facebook.objectif.impression.prix = 45;
    objet.instagram.objectif.impression.prix = 45;

    objet.facebook.objectif.interaction.prix = 4;
    objet.instagram.objectif.interaction.prix = 4;

    objet.facebook.objectif.story.prix = 90;
    objet.instagram.objectif.story.prix = 90;

    objet.youtube.objectif["vue video"].prix = 1.8;
    objet.facebook.objectif["vue video"].prix = 1.4;
    objet.instagram.objectif["vue video"].prix = 1.4;

    objet.youtube.objectif.impression.prix = 870;
    objet.linkedin.objectif.impression.prix = 3680;
    objet.display.objectif.impression.prix = 30;

    objet.facebook.ratio = 2.3;
    objet.instagram.ratio = 2.3;
    objet.linkedin.ratio = 2.3;
    objet.display.ratio = 5;
    objet.youtube.ratio = 5;
    objet.snapchat.ratio = 2.3;
    objet.search.ratio = 2.3;
  }

  // else if (client === "BIOPHARM") {
  //   objet.facebook.objectif.impression.prix = 40;
  //   objet.instagram.objectif.impression.prix = 50;

  //   objet.facebook.objectif.interaction.prix = 4;
  //   objet.instagram.objectif.interaction.prix = 4;

  //   objet.facebook.objectif.story.prix = 90;
  //   objet.instagram.objectif.story.prix = 90;

  //   objet.youtube.objectif["vue video"].prix = 1.8;
  //   objet.facebook.objectif["vue video"].prix = 0.8;
  //   objet.instagram.objectif["vue video"].prix = 1.2;

  //   objet.youtube.objectif.impression.prix = 350;
  //   objet.linkedin.objectif.impression.prix = 3680;
  //   objet.display.objectif.impression.prix = 20;

  //   objet.facebook.ratio = 2.3;
  //   objet.instagram.ratio = 2.3;
  //   objet.linkedin.ratio = 2.3;
  //   objet.display.ratio = 5;
  //   objet.youtube.ratio = 5;
  //   objet.snapchat.ratio = 2.3;
  //   objet.search.ratio = 2.3;
  // }
  else if (
    client === "ACTIVIA" ||
    client === "DANETTE" ||
    client === "DANONE YAOURT" ||
    client === "ACTIMEL" ||
    client === "DJURDJURA" ||
    client === "DANAO" ||
    client === "HOP"
  ) {
    console.log("c'est danone");
    objet.facebook.objectif.impression.prix = 60;
    objet.facebook.objectif["vue video"].prix = 1.93;
    objet.facebook.objectif.traffic.prix = 4.59;
    objet.facebook.objectif.interaction.prix = 4;
    objet.instagram.objectif.interaction.prix = 4;
    objet.instagram.objectif.impression.prix = 60;
    objet.instagram.objectif.story.prix = 90;
    objet.youtube.objectif["vue video"].prix = 1.8;
    objet.youtube.objectif.impression.prix = 500;
    objet.linkedin.objectif.impression.prix = 4500;
    objet.display.objectif.impression.prix = 30;
    objet.display.objectif.traffic.prix = 6.4;

    objet.facebook.tauxdechange.valeur = tauxdechange.dirhem.valeur;
    objet.facebook.tauxdechange.symbole = tauxdechange.dirhem.symbole;
    objet.instagram.tauxdechange.valeur = tauxdechange.dirhem.valeur;
    objet.instagram.tauxdechange.symbole = tauxdechange.dirhem.symbole;

    objet.facebook.ratio = 3;
    objet.instagram.ratio = 3;
    objet.linkedin.ratio = 3;
    objet.display.ratio = 5;
    objet.youtube.ratio = 5;
    objet.snapchat.ratio = 3;
    objet.search.ratio = 2.3;
  } else if (client === "EURL PHARMALLIANCE") {
    objet.facebook.objectif.impression.prix = 55;
    objet.facebook.objectif["vue video"].prix = 2.3;
    objet.facebook.objectif.traffic.prix = 7.8;
    objet.facebook.objectif.interaction.prix = 4;
    objet.instagram.objectif.interaction.prix = 4;
    objet.instagram.objectif.impression.prix = 55;
    objet.instagram.objectif.story.prix = 90;
    objet.facebook.objectif.story.prix = 90;
    objet.youtube.objectif["vue video"].prix = 1.8;
    objet.youtube.objectif.impression.prix = 935.39;
    objet.linkedin.objectif.impression.prix = 4500;
    objet.display.objectif.impression.prix = 20;
    objet.display.objectif.traffic.prix = 5.6;

    objet.facebook.ratio = 2.3;
    objet.instagram.ratio = 2.3;
    objet.linkedin.ratio = 2.3;
    objet.display.ratio = 4;
    objet.youtube.ratio = 5;
    objet.snapchat.ratio = 3;
    objet.search.ratio = 2.3;
  } else if (client === "LESIEUR" || client === "HOLIDAY INN") {
    console.log("c'est lesieur");
    objet.facebook.objectif.impression.prix = 50;
    objet.facebook.objectif["vue video"].prix = 1.2;
    objet.facebook.objectif.traffic.prix = 7.8;
    objet.facebook.objectif.interaction.prix = 4;
    objet.instagram.objectif.interaction.prix = 4;
    objet.instagram.objectif.impression.prix = 55;
    objet.instagram.objectif.story.prix = 90;
    objet.facebook.objectif.story.prix = 90;
    objet.youtube.objectif["vue video"].prix = 1.8;
    objet.youtube.objectif.impression.prix = 935.39;
    objet.linkedin.objectif.impression.prix = 4500;
    objet.display.objectif.impression.prix = 20;
    objet.display.objectif.traffic.prix = 5.6;

    objet.facebook.ratio = 2.3;
    objet.instagram.ratio = 2.3;
    objet.linkedin.ratio = 2.3;
    objet.display.ratio = 5;
    objet.youtube.ratio = 5;
    objet.snapchat.ratio = 3;
    objet.search.ratio = 2.3;
  } else if (client === "LG") {
    console.log("c'est lg");
    objet.facebook.objectif.impression.prix = 60;
    objet.facebook.objectif["vue video"].prix = 2.3;
    objet.facebook.objectif.traffic.prix = 7.8;
    objet.instagram.objectif.impression.prix = 70;
    objet.instagram.objectif["vue video"].prix = 2.3;
    objet.instagram.objectif.story.prix = 100;
    objet.youtube.objectif["vue video"].prix = 3;
    objet.youtube.objectif.impression.prix = 875;
    objet.linkedin.objectif.impression.prix = 4500;
    objet.display.objectif.impression.prix = 102.5;
    objet.display.objectif.traffic.prix = 8;

    objet.facebook.tauxdechange.valeur = tauxdechange.dollar.valeur;
    objet.facebook.tauxdechange.symbole = tauxdechange.dollar.symbole;
    objet.instagram.tauxdechange.valeur = tauxdechange.dollar.valeur;
    objet.instagram.tauxdechange.symbole = tauxdechange.dollar.symbole;
    objet.search.tauxdechange.valeur = tauxdechange.dollar.valeur;
    objet.search.tauxdechange.symbole = tauxdechange.dollar.symbole;
    objet.display.tauxdechange.valeur = tauxdechange.dollar.valeur;
    objet.display.tauxdechange.symbole = tauxdechange.dollar.symbole;
    objet.youtube.tauxdechange.valeur = tauxdechange.dollar.valeur;
    objet.youtube.tauxdechange.symbole = tauxdechange.dollar.symbole;

    objet.facebook.ratio = 1.88;
    objet.instagram.ratio = 1.88;
    objet.linkedin.ratio = 1.88;
    objet.display.ratio = 1.88;
    objet.youtube.ratio = 1.88;
    objet.snapchat.ratio = 1.88;
    objet.search.ratio = 1.88;
  } else if (client === "PALC ALLIN") {
    objet.facebook.objectif.impression.prix = 50;
    objet.facebook.objectif["vue video"].prix = 1.8;
    objet.facebook.objectif.traffic.prix = 7.8;
    objet.instagram.objectif.impression.prix = 50;
    objet.instagram.objectif["vue video"].prix = 1.5;
    objet.instagram.objectif.story.prix = 100;
    objet.youtube.objectif["vue video"].prix = 1.8;
    objet.youtube.objectif.impression.prix = 875;
    objet.linkedin.objectif.impression.prix = 4500;
    objet.display.objectif.impression.prix = 30;
    objet.display.objectif.traffic.prix = 5.6;

    objet.facebook.ratio = 2.5;
    objet.instagram.ratio = 2.5;
    objet.linkedin.ratio = 3;
    objet.display.ratio = 5;
    objet.youtube.ratio = 5;
    objet.snapchat.ratio = 3;
    objet.search.ratio = 2.3;
  } else {
    console.log("tarifs par default");
    // objet.facebook.objectif.impression.prix = 50;
    // objet.facebook.objectif["vue video"].prix = 1.8;
    // objet.youtube.objectif.impression.prix = 300;
    // objet.youtube.objectif["vue video"].prix = 1.8;
    // objet.search.objectif.traffic.prix = 60;
    // objet.instagram.objectif.impression.prix = 50;
    // objet.instagram.objectif.story.prix = 90;
    // objet.display.objectif.impression.prix = 20;
    // objet.facebook.ratio = 1;
    // objet.instagram.ratio = 2.3;
    // objet.linkedin.ratio = 3;
    // objet.display.ratio = 5;
    // objet.youtube.ratio = 5;
    // objet.snapchat.ratio = 3;
    // objet.search.ratio = 2.3;
  }

  //

  let prix_unitaire = objet[network].objectif[objectif].prix;
  let articleZoho = objet[network].objectif[objectif].articleZoho;
  let unite = objet[network].objectif[objectif].unite;
  let taux_de_change = objet[network].tauxdechange.valeur;
  let taux_de_change_symbole = objet[network].tauxdechange.symbole;
  let ratio = objet[network].ratio;
  let kpi;
  let encher_max = Number(prix_unitaire / taux_de_change / ratio).toFixed(3);
  let budget_devise = Number(budget / taux_de_change / ratio).toFixed(2);
  let kpiZoho = budget / prix_unitaire;
  if (unite === "CPM") {
    kpi = Math.round((budget / prix_unitaire) * 1000);
    unite = "Impressions";
    // Math.round(0.9)
  } else {
    kpi = Math.round(budget / prix_unitaire);
  }
  let objs = {
    kpi,
    unite,
    budget_devise,
    taux_de_change_symbole,
    encher_max,
    prix_unitaire,
    unite,
    kpiZoho,
    articleZoho,
  };

  return objs;
}

exports.getPrice = getPrice;
